<template>
  <div class="security-inspection-summary-ledger flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span>安全检查汇总</span>
      </div>
      <div class="tp-right flex-center-v">
        <el-input v-model="departmentName" placeholder="单位名称"  clearable size="small" style="margin-right:15px;width: 200px"></el-input>
        <el-date-picker
            size="small"
            v-model="timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            style="width: 400px;margin-right: 10px;">
        </el-date-picker>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" border @sort-change="sortChange" tooltip-effect="light myTooltips">
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="name" label="单位名称" align="center"></el-table-column>
<!--        <el-table-column prop="totalCount" label="检查总数" align="center" sortable="custom"></el-table-column>-->
        <el-table-column prop="checkCount" label="隐患总数" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="importantCount" label="重大隐患"  align="center" sortable="custom"></el-table-column>
        <el-table-column prop="rectifyCount" label="待整改" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="reviewCount" label="待复查" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="notRectifyLimitCount" label="超期未整改" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="importantNotLimitCount" label="重大隐患超期未整改" align="center" sortable="custom"></el-table-column>
        <el-table-column prop="rectifyRate" label="整改率" align="center" sortable="custom">
          <template slot-scope="{row}">
            {{row.rectifyRate}}%
          </template>
        </el-table-column>
        <el-table-column prop="closureRate" label="闭合率" align="center" sortable="custom">
          <template slot-scope="{row}">
            {{row.closureRate}}%
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="total > searchData.pageSize"
          background
          :current-page.sync="searchData.pageCurrent"
          :page-size="searchData.pageSize"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import apis from './apis';

export default {
  name: "security-inspection-summary-ledger",

  components: {},

  props: {},

  data() {
    return {
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10,
        checkStartDate: '',
        checkEndDate: '',
        sort: '',
        sortField: ''
      },
      departmentName:'',
      total: 0,
      timer: ''
    }
  },

  computed: {
    ...mapGetters(['companyId'])
  },

  watch: {
    companyId() {
      this.search()
    }
  },

  mounted() {
    if(this.companyId) this.getTableList()
  },

  methods: {
    async getTableList() {
      this.searchData.departmentCode = this.companyId
      const result = await apis.pageTotalData(this.searchData)
      this.tableList = result.list
      this.total = result.total
    },
    // 查询
    search() {
      if (this.timer) {
        this.searchData.checkStartDate = this.timer[0]
        this.searchData.checkEndDate = this.timer[1]
      } else {
        this.searchData.checkStartDate = ''
        this.searchData.checkEndDate = ''
      }
      this.searchData.departmentName = this.departmentName
      this.searchData.pageCurrent = 1
      this.getTableList().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    },
    sortChange({ prop, order }) {
      if (order) {
        if (order == 'ascending') this.searchData.sort = 'asc'
        else this.searchData.sort = 'desc'
        this.searchData.sortField = prop
        this.getTableList().async
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.security-inspection-summary-ledger {
  height 100%
  padding 10px
}
</style>
